import { AxiosError } from "axios";
import { Axios } from "../base";
import { TSignup } from "types/login";

export const postSignup = async ({ userId, password }: TSignup): Promise<{ message: string; status: number }> => {
  try {
    const { data } = await Axios.post(`/staff/signup`, { userId, password });
    return data;
  } catch (error) {
    if ((error as AxiosError).message === "대상이 이미 존재합니다.") {
      throw Error("이미 가입된 아이디입니다.\n다른 아이디를 입력해주세요.");
    } else {
      throw Error("회원가입에 실패하였습니다.");
    }
  }
};
