import { getActiveDau } from "api/activeUser";
import queryKeys from "constants/queryKeys";
import AnalysisMain from "pages/AnalysisMain";
import { useQuery } from "react-query";
import { formatExcelTitle, formatRecentWeek, recent4WeeksAgo, yesterday } from "utils/dateCalculator";
import AreaGraph from "common/graph/AreaGraph";

type TProps = {
  enabled: boolean;
};

export default function Recent4DAU({ enabled }: TProps) {
  const { data: recent4DAU = [] } = useQuery(
    [queryKeys.GET_RECENT_4WEEKS_DAU],
    () => getActiveDau(recent4WeeksAgo, yesterday),
    { enabled }
  );

  return (
    <AnalysisMain
      title={`${recent4WeeksAgo} ~ ${yesterday}`}
      excelTitle={formatExcelTitle("DAU", recent4WeeksAgo, yesterday)}
    >
      <AreaGraph
        dataList={recent4DAU}
        handleDateFormat={formatRecentWeek}
        interval={6}
        legendTitle="일일 활성 사용자 수(DAU)"
      />
    </AnalysisMain>
  );
}
