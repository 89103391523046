import { TDateCount, TDateCountList } from "types/graph";
import { Axios } from "./base";

export const getActiveDau = async (startDate: string, endDate: string): Promise<TDateCount[]> => {
  const { data } = await Axios.get(`/activeuser/dau`, { params: { startDate, endDate } });
  return data.data;
};

export const getActiveMau = async (startDate: string, endDate: string): Promise<TDateCount[]> => {
  const { data } = await Axios.get(`/activeuser/mau`, { params: { startDate, endDate } });
  return data.data;
};

export const getRealtimeDAU = async (): Promise<TDateCount> => {
  const { data } = await Axios.get(`/activeuser/dau/realtime`);
  return data.data;
};

export const getRealtimeMAU = async (): Promise<TDateCount> => {
  const { data } = await Axios.get(`/activeuser/mau/realtime`);
  return data.data;
};

export const getDayOfWeekDAU = async (startDate: string, endDate: string): Promise<TDateCountList[]> => {
  const { data } = await Axios.get(`/activeuser/dau/day-of-week`, { params: { startDate, endDate } });
  return data.data;
};

export const getWeekByWeekDAU = async (startDate: string, endDate: string): Promise<TDateCountList[]> => {
  const { data } = await Axios.get(`/activeuser/dau/week-by-week`, { params: { startDate, endDate } });
  return data.data;
};
