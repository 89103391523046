import Login from "pages/login/Login";
import Home from "pages/home/Home";
import { Route, Routes } from "react-router-dom";
import SignUp from "pages/login/SignUp";
import DAU from "pages/activeUser/DAU";
import MAU from "pages/activeUser/MAU";
import Total from "pages/verification/Total";
import Channel from "pages/verification/Channel";
import DataTrade from "pages/reward/DataTrade";
import Attendance from "pages/reward/Attendance";
import Poll from "pages/reward/Poll";
import Survey from "pages/reward/Survey";
import ChallengePoint from "pages/reward/ChallengePoint";
import ChallengeSuccess from "pages/reward/ChallengeSuccess";
import ProtectedRoute from "pages/ProtectedRoute";
import Payment from "pages/pointmall/Payment";
import Ranking from "pages/pointmall/Ranking";

function App() {
  return (
    <Routes>
      <Route index element={<Login />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<SignUp />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/home" element={<Home />} />
        <Route path="active">
          <Route path="dau" element={<DAU />} />
          <Route path="mau" element={<MAU />} />
        </Route>
        <Route path="auth">
          <Route path="total" element={<Total />} />
          <Route path="channel" element={<Channel />} />
        </Route>
        <Route path="reward">
          <Route path="data-trade" element={<DataTrade />} />
          <Route path="attendance" element={<Attendance />} />
          <Route path="poll" element={<Poll />} />
          <Route path="survey" element={<Survey />} />
        </Route>
        <Route path="challenge">
          <Route path="point" element={<ChallengePoint />} />
          <Route path="success" element={<ChallengeSuccess />} />
        </Route>
        <Route path="pointmall">
          <Route path="payment" element={<Payment />} />
          <Route path="ranking" element={<Ranking />} />
        </Route>
      </Route>
    </Routes>
  );
}
export default App;
