import styles from "styles/common/graphCard.module.scss";
import { MouseEventHandler, ReactNode } from "react";

type TProps = {
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
};

export default function GraphCard({ children, onClick }: TProps) {
  return (
    <div className={styles.graphCardWrapper} onClick={onClick}>
      <div className={styles.graphCard}>{children}</div>
    </div>
  );
}
