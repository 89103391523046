import Tab from "common/Tab";
import { CarouselNavigator } from "common/CarouselNavigator";
import { useState } from "react";
import Recent4DAU from "./Recent4DAU";
import ThisMonthDAU from "./ThisMonthDAU";
import DayOfWeekDAU from "./DayOfWeekDAU";
import WeekByWeekDAU from "./WeekByWeekDAU";

const TABS = ["최근 4주", "이번 달", "요일별", "주차별"];

export default function DAU() {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const handleActiveTab = (value: string) => {
    setActiveTab(value);
  };

  return (
    <div>
      <Tab tabs={TABS} activeTab={activeTab} onClickTab={handleActiveTab} />
      <CarouselNavigator tabIndex={TABS.indexOf(activeTab)} slideEvent={(e) => handleActiveTab(TABS[e])}>
        <Recent4DAU enabled={activeTab === "최근 4주"} />

        <ThisMonthDAU enabled={activeTab === "이번 달"} />

        <DayOfWeekDAU enabled={activeTab === "요일별"} />

        <WeekByWeekDAU enabled={activeTab === "주차별"} />
      </CarouselNavigator>
    </div>
  );
}
