import Tab from "common/Tab";
import { CarouselNavigator } from "common/CarouselNavigator";
import { useState } from "react";
import AreaGraph from "common/graph/AreaGraph";
import dayjs from "dayjs";
import AnalysisMain from "pages/AnalysisMain";
import { useQuery } from "react-query";
import { getActiveMau } from "api/activeUser";
import queryKeys from "constants/queryKeys";
import { firstDayOfYear, lastDayOfLastMonth, recent1YearAgo } from "utils/dateCalculator";

const TABS = ["최근 1년", "올해"];

export default function MAU() {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const { data: recent1YearMAU = [] } = useQuery([queryKeys.GET_RECENT_1YEAR_MAU], () =>
    getActiveMau(recent1YearAgo, lastDayOfLastMonth)
  );
  const { data: thisYearMAU = [] } = useQuery([queryKeys.GET_THIS_YEAR_MAU], () =>
    getActiveMau(firstDayOfYear, lastDayOfLastMonth)
  );

  const handleActiveTab = (value: string) => {
    setActiveTab(value);
  };

  const formatRecentMonth = (xTick: string) => {
    const today = dayjs();
    const targetMonth = dayjs(xTick).diff(today, "M") * -1;
    return `${targetMonth}달전`;
  };

  const formatMonth = (xTick: string) => {
    return `${dayjs(xTick).format("M")}월`;
  };

  const formatPeriodTitle = (start: string) => {
    const startText = dayjs(start).startOf("M").format("YYYY-MM-DD");
    return `${startText} ~ ${lastDayOfLastMonth}`;
  };

  const formatExcelTitle = (start: string, end: string) => {
    const startDate = dayjs(start).format("YYMMDD");
    const endDate = dayjs(end).format("YYMMDD");

    return `MAU_${startDate}_${endDate}`;
  };

  return (
    <div>
      <Tab tabs={TABS} activeTab={activeTab} onClickTab={handleActiveTab} />
      <CarouselNavigator tabIndex={TABS.indexOf(activeTab)} slideEvent={(e) => handleActiveTab(TABS[e])}>
        <div>
          <AnalysisMain
            title={formatPeriodTitle(recent1YearMAU[0]?.date)}
            excelTitle={formatExcelTitle(recent1YearMAU[0]?.date, lastDayOfLastMonth)}
          >
            <AreaGraph
              dataList={recent1YearMAU}
              handleDateFormat={formatRecentMonth}
              interval={2}
              legendTitle="월간 활성 사용자 수(MAU)"
            />
          </AnalysisMain>
        </div>

        <div>
          <AnalysisMain
            title={formatPeriodTitle(thisYearMAU[0]?.date)}
            excelTitle={formatExcelTitle(thisYearMAU[0]?.date, lastDayOfLastMonth)}
          >
            <AreaGraph
              dataList={thisYearMAU}
              handleDateFormat={formatMonth}
              interval={2}
              legendTitle="월간 활성 사용자 수(MAU)"
            />
          </AnalysisMain>
        </div>
      </CarouselNavigator>
    </div>
  );
}
