import styles from "styles/common/graph/areaGraph.module.scss";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { TDateCount } from "types/graph";
import dayjs from "dayjs";
import "dayjs/locale/ko";
dayjs.locale("ko");

type TProps = {
  dataList: TDateCount[];
  handleDateFormat: (xTick: string) => string;
  interval: number;
  legendTitle: string;
};

export default function AreaGraph({ dataList, handleDateFormat, interval, legendTitle }: TProps) {
  const renderCustomLegendText = () => {
    return <span className={styles.legend}>{legendTitle}</span>;
  };

  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        data={dataList}
        margin={{
          top: 30,
          right: 30,
          left: 10,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            paddingLeft: "28px",
          }}
          formatter={renderCustomLegendText}
        />
        <XAxis dataKey="date" tick={{ fontSize: 12 }} tickFormatter={handleDateFormat} interval={interval} />
        <YAxis
          tick={{ fontSize: 12 }}
          label={{
            fontSize: "12",
            offset: "8",
            value: "(단위 : 명)",
            position: "insideTopLeft",
          }}
          domain={[
            (dataMin: number) => Math.floor((dataMin * 0.9) / 100) * 100,
            (dataMax: number) => Math.ceil((dataMax * 1.1) / 100) * 100,
          ]}
          tickFormatter={(yTick) => {
            return yTick.toLocaleString();
          }}
        />
        <Tooltip
          contentStyle={{
            borderRadius: "8px",
            backgroundColor: "#f4fbff",
            border: 0,
            fontSize: 14,
            fontWeight: 500,
            boxShadow: "0 2px 4px #a9aeb1",
          }}
          labelStyle={{ fontWeight: 600 }}
          trigger="click"
          formatter={(value: number) => value.toLocaleString()}
        />
        <Area type="monotone" dataKey="count" stroke="#8884d8" fill="#8884d8" />
      </AreaChart>
    </ResponsiveContainer>
  );
}
