import styles from "styles/pages/main/home.module.scss";
import DataCard from "./DataCard";
import GraphCard from "common/GraphCard";
import AreaGraph from "common/graph/AreaGraph";
import { useQuery } from "react-query";
import { getActiveDau, getRealtimeDAU, getRealtimeMAU } from "api/activeUser";
import { TDataCardList, TDateCount } from "types/graph";
import queryKeys from "constants/queryKeys";
import { getPaymentAmount } from "api/reward";
import { firstDayOfMonth, formatRecentWeek, recent4WeeksAgo, yesterday } from "utils/dateCalculator";
import { getTotalPointUsed } from "api/pointmall";
import { getTotalVerification } from "api/verification";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

export default function Home() {
  const navigate = useNavigate();
  const { data: recent4DAU = [] } = useQuery([queryKeys.GET_RECENT_4WEEKS_DAU], () =>
    getActiveDau(recent4WeeksAgo, yesterday)
  );

  const { data: homeDataList = [] } = useQuery([queryKeys.GET_HOME_DATA], async () => {
    const results = await Promise.allSettled([
      getRealtimeDAU(),
      getRealtimeMAU(),
      getTotalVerification(firstDayOfMonth, yesterday),
      getPaymentAmount(firstDayOfMonth, yesterday),
      getTotalPointUsed(firstDayOfMonth, yesterday),
    ]);
    return results.map((result) => (result.status === "fulfilled" ? result.value : null));
  });

  const [realtimeDAU, realtimeMAU, verificationAmount, rewardAmount, pointAmount] = homeDataList as [
    TDateCount,
    TDateCount,
    TDateCount[],
    { amountSum: number },
    { totalPoint: number }
  ];

  const sumOfverificationAmount = useMemo(
    () => verificationAmount?.reduce((sum, item) => sum + item.count, 0),
    [verificationAmount]
  );

  const DataCardList: TDataCardList[] = [
    {
      title: "실시간 DAU",
      value: realtimeDAU?.count,
      description: "오늘 기준(명)",
    },
    {
      title: "실시간 MAU",
      value: realtimeMAU?.count,
      description: "이번달 기준(명)",
    },
    {
      title: "본인인증 사용자 수",
      value: sumOfverificationAmount,
      description: "이번달 기준(명)",
    },
    {
      title: "리워드 지급액",
      value: Math.floor((rewardAmount?.amountSum ?? 0) / 10000),
      description: "이번달 기준(만원)",
    },
    {
      title: "사용된 포인트 총액",
      value: pointAmount?.totalPoint.toLocaleString(),
      description: " 이번달 기준(P)",
    },
  ];

  return (
    <div className={styles.container}>
      <ul className={styles.titleWrapper}>
        <li className={styles.title}>최근 4주 DAU</li>
        <li className={styles.description}>그래프를 눌러 상세 데이터를 확인해보세요</li>
      </ul>

      <GraphCard onClick={() => navigate("/active/dau")}>
        <AreaGraph
          dataList={recent4DAU}
          handleDateFormat={formatRecentWeek}
          interval={6}
          legendTitle="일일 활성 사용자 수(DAU)"
        />
      </GraphCard>

      <ul className={styles.titleWrapper}>
        <li className={styles.title}>한눈에 보는 주요 데이터</li>
        <li className={styles.description}>중요 정보를 빠르게 확인해보세요</li>
      </ul>

      <div className={styles.dataCardList}>
        {DataCardList?.map(({ title, description, value }) => (
          <DataCard key={title} title={title} value={value} description={description} />
        ))}
      </div>
    </div>
  );
}
