import styles from "styles/pages/login/login.module.scss";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { TLogin } from "types/login";
import { useMutation } from "react-query";
import { postLogin } from "api/login/login";
import { EyesOffIcon, EyesOnIcon } from "assets/svgs";

export default function Login() {
  const navigate = useNavigate();
  const [loginError, setLoginError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const { mutate: PostLoginMutate } = useMutation(postLogin);
  const { register, handleSubmit } = useForm<TLogin>({ mode: "onSubmit" });
  const isLoggedIn = localStorage.getItem("accessToken");

  const clickToLogin = ({ userId, password }: TLogin) => {
    PostLoginMutate(
      {
        userId,
        password,
      },
      {
        onSuccess: () => {
          navigate("/home");
        },
        onError: () => {
          setLoginError(true);
        },
      }
    );
  };

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  useEffect(() => {
    isLoggedIn && navigate("/home");
  }, [isLoggedIn]);

  useEffect(() => {
    const updateVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    updateVh();
    window.addEventListener("resize", updateVh);

    return () => {
      window.removeEventListener("resize", updateVh);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <h1>MOAI</h1>
      <p>마이디 운영 지표 조회 서비스</p>
      <form className={styles.loginForm} onSubmit={handleSubmit(clickToLogin)}>
        <div className={styles.idInput}>
          <input
            placeholder="아이디 입력"
            autoComplete="username"
            {...register("userId", {
              required: true,
            })}
          />
          <span>@snplab.io</span>
        </div>
        <div className={styles.pwWrapper}>
          <input
            placeholder="비밀번호 입력"
            type={showPassword ? "text" : "password"}
            autoComplete="current-password"
            {...register("password", {
              required: true,
            })}
            className={styles.pwInput}
          />
          <span className={styles.eyesIcon} onClick={togglePasswordVisibility}>
            {showPassword ? <EyesOnIcon /> : <EyesOffIcon />}
          </span>
        </div>
        {loginError && <p className={styles.error}>아이디 또는 비밀번호가 일치하지 않습니다.</p>}
        <button type="submit">로그인</button>
      </form>
      <button className={styles.signup} type="button" onClick={() => navigate("/signup")}>
        회원가입 하기
      </button>
      <footer className={styles.logo}>ⓒ SNPLab Inc.</footer>
    </div>
  );
}
