enum ErrorCode {
  INVALID_ARGUMENT = "INVALID_ARGUMENT",
  RESOURCE_NOT_FOUND = "RESOURCE_NOT_FOUND",
  UNSUPPORTED_HTTPVERB = "UNSUPPORTED_HTTPVERB",
  INTERNAL_SERVER_ERROR = "INTERNAL_SERVER_ERROR",
  UNKNOWN_ERROR = "UNKNOWN_ERROR",
  ERR_NOT_FOUND_DATA = "ERR_NOT_FOUND_DATA",
  UNAUTHORIZED = "UNAUTHORIZED",
  REQUEST_TIMEOUT_EXCEEDED = "REQUEST_TIMEOUT_EXCEEDED",
  ACCESS_DENIED = "ACCESS_DENIED",
}

export default ErrorCode;

export enum ERROR_FRONT_CODE {
  /* -------------------------------------------------------------------------------------------------
   * 브릿지 인터페이스에서 발생할 수 있는 에러 코드 1 ~ 100
   * -----------------------------------------------------------------------------------------------*/
  MISSING_MYD3_DATA = "1", // 앱에서 myd3 데이터를 전달하지 않았습니다.
  INVALID_RETURN_VALUE = "2", // 앱에서 반환값을 잘못 전달했습니다.
  BRIDGE_INTERFACE_NOT_FOUND = "3", // 앱에서 브릿지 인터페이스가 존재하지 않습니다.
  FUNCTION_NOT_FOUND = "4", // 웹에서 해당 함수가 존재하지 않습니다.
}
