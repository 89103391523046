import dayjs from "dayjs";
import isoWeek from "dayjs/plugin/isoWeek";
import weekOfYear from "dayjs/plugin/weekOfYear";
dayjs.extend(isoWeek);
dayjs.extend(weekOfYear);

export const firstDayOfYear = dayjs().startOf("y").format("YYYY-MM-DD");
export const firstDayOfMonth = dayjs().startOf("M").format("YYYY-MM-DD");
export const recent4WeeksAgo = dayjs().subtract(29, "days").format("YYYY-MM-DD");
export const recent1YearAgo = dayjs().subtract(12, "month").format("YYYY-MM-DD");
export const monday4WeeksAgo = dayjs().subtract(4, "w").startOf("w").add(1, "d").format("YYYY-MM-DD");
export const monday8WeeksAgo = dayjs().subtract(8, "w").startOf("w").add(1, "d").format("YYYY-MM-DD");
export const lastDayOfLastMonth = dayjs().subtract(1, "month").endOf("M").format("YYYY-MM-DD");
export const lastSunday = dayjs().startOf("w").format("YYYY-MM-DD");
export const yesterday = dayjs().subtract(1, "days").format("YYYY-MM-DD");

export const formatExcelTitle = (title: string, start: string, end: string) => {
  const startDate = dayjs(start).format("YYMMDD");
  const endDate = dayjs(end).format("YYMMDD");

  return `${title}_${startDate}_${endDate}`;
};

export const formatRecentWeek = (xTick: string) => {
  const today = dayjs();
  const targetWeek = dayjs(xTick).diff(today, "w") * -1;

  return targetWeek ? `${targetWeek}주전` : "";
};

export const formatDayTicks = (xTick: string) => {
  return `${dayjs(xTick).format("D")}일`;
};

export const handleCountOfWeek = (targetDate: string) => {
  const date = dayjs(targetDate);
  const startOfMonth = date.startOf("month");
  const currentWeek = date.week();
  const startOfMonthWeek = startOfMonth.week();

  let weekInMonth = currentWeek - startOfMonthWeek + 1;

  if (date.day() === 0) {
    if (weekInMonth <= 0) {
      const lastMonth = date.subtract(1, "month");
      weekInMonth = lastMonth.endOf("month").week() - lastMonth.startOf("month").week() + 1;
    }
  }

  if (startOfMonth.day() === 0) {
    weekInMonth = date.isSame(startOfMonth) ? 1 : weekInMonth + 1;
  }

  return {
    month: date.format("M"),
    week: weekInMonth,
  };
};
