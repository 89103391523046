import { getDayOfWeekDAU } from "api/activeUser";
import LineGraph from "common/graph/LineGraph";
import queryKeys from "constants/queryKeys";
import AnalysisMain from "pages/AnalysisMain";
import { useQuery } from "react-query";
import { formatExcelTitle, lastSunday, monday8WeeksAgo } from "utils/dateCalculator";

type TProps = {
  enabled: boolean;
};

export default function DayOfWeekDAU({ enabled }: TProps) {
  const { data: dayOfWeekDAU = [] } = useQuery(
    [queryKeys.GET_DAY_OF_WEEK_DAU],
    () => getDayOfWeekDAU(monday8WeeksAgo, lastSunday),
    { enabled }
  );

  const formatRecentWeekOfWeek = (xTick: string) => {
    const weekAgo = dayOfWeekDAU.length - parseInt(xTick) + 1;
    return `${weekAgo}주전`;
  };
  return (
    <AnalysisMain
      title={`${monday8WeeksAgo} ~ ${lastSunday}`}
      excelTitle={formatExcelTitle("요일별 DAU", monday8WeeksAgo, lastSunday)}
    >
      <LineGraph dataList={dayOfWeekDAU} handleDateFormat={formatRecentWeekOfWeek} interval={0} />
    </AnalysisMain>
  );
}
