import Tab from "common/Tab";
import { CarouselNavigator } from "common/CarouselNavigator";
import AnalysisMain from "pages/AnalysisMain";
import { useMemo, useState } from "react";
import { firstDayOfMonth, formatExcelTitle, lastSunday, monday4WeeksAgo, yesterday } from "utils/dateCalculator";
import BarGraph from "common/graph/BarGraph";
import { useQuery } from "react-query";
import queryKeys from "constants/queryKeys";
import { getChallengeRate } from "api/challenge";
import { TChallengeRate } from "types/graph";

const TABS = ["최근 4주", "이번 달"];

export default function ChallengeSuccess() {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const { data: recent4Data = [] } = useQuery(
    [queryKeys.GET_RECENT4_CHALLENGE_RATE],
    () => getChallengeRate(monday4WeeksAgo, lastSunday),
    { enabled: activeTab === "최근 4주" }
  );

  const { data: thisMonthData = [] } = useQuery(
    [queryKeys.GET_THIS_MONTH_CHALLENGE_RATE],
    () => getChallengeRate(firstDayOfMonth, yesterday),
    { enabled: activeTab === "이번 달" }
  );

  const handleActiveTab = (value: string) => {
    setActiveTab(value);
  };

  const formatRecentWeek = (xTick: number) => {
    const weekAgo = 4 - xTick + 1;
    return `${weekAgo}주전`;
  };

  const formatLegendText = () => {
    return <span style={{ color: "black" }}>챌린지 성공률</span>;
  };

  const changeDataFormat = (data: TChallengeRate[]) =>
    data.map(({ week, successRate }) => ({
      week,
      amount: [successRate],
    }));

  const formattedRecent4Data = useMemo(() => changeDataFormat(recent4Data), [recent4Data]);
  const formattedThisMonthData = useMemo(() => changeDataFormat(thisMonthData), [thisMonthData]);

  return (
    <div>
      <Tab tabs={TABS} activeTab={activeTab} onClickTab={handleActiveTab} />
      <CarouselNavigator tabIndex={TABS.indexOf(activeTab)} slideEvent={(e) => handleActiveTab(TABS[e])}>
        <AnalysisMain
          title={`${monday4WeeksAgo} ~ ${lastSunday}`}
          excelTitle={formatExcelTitle("그룹루틴 챌린지 성공률", monday4WeeksAgo, lastSunday)}
        >
          <BarGraph
            dataList={formattedRecent4Data}
            handleLegendText={formatLegendText}
            handleDateFormat={formatRecentWeek}
            pageTitle="그룹루틴 챌린지 성공률"
            tabType={activeTab}
          />
        </AnalysisMain>
        <AnalysisMain
          title={`${firstDayOfMonth} ~ ${yesterday}`}
          excelTitle={formatExcelTitle("그룹루틴 챌린지 성공률", firstDayOfMonth, yesterday)}
        >
          <BarGraph
            dataList={formattedThisMonthData}
            handleLegendText={formatLegendText}
            handleDateFormat={(xTick) => `${xTick}주차`}
            pageTitle="그룹루틴 챌린지 성공률"
            tabType={activeTab}
          />
        </AnalysisMain>
      </CarouselNavigator>
    </div>
  );
}
