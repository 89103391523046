import GraphCard from "common/GraphCard";
import ExcelDownload from "common/ExcelDownload";
import styles from "styles/pages/analysisMain.module.scss";
import { ReactNode } from "react";

type TProps = {
  children: ReactNode;
  title: string;
  excelTitle: string;
};

export default function AnalysisMain({ children, title, excelTitle }: TProps) {
  return (
    <div>
      <section className={styles.section}>
        <p className={styles.graphTitle}>{title}</p>
        <GraphCard>{children}</GraphCard>
      </section>
      {/* <section>
        <p className={styles.downloadTitle}>원본 데이터 다운로드</p>
        <ExcelDownload text={excelTitle} />
      </section> */}
    </div>
  );
}
