import Tab from "common/Tab";
import { CarouselNavigator } from "common/CarouselNavigator";
import { useState } from "react";
import AnalysisMain from "pages/AnalysisMain";
import { useQuery } from "react-query";
import queryKeys from "constants/queryKeys";
import { getChannelVerification } from "api/verification";
import LineGraph from "common/graph/LineGraph";
import {
  firstDayOfMonth,
  formatDayTicks,
  formatExcelTitle,
  formatRecentWeek,
  recent4WeeksAgo,
  yesterday,
} from "utils/dateCalculator";

const TABS = ["최근 4주", "이번 달"];

export default function Channel() {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const { data: recent4Data = [] } = useQuery([queryKeys.GET_RECENT4_CHANNEL_VERIFI], () =>
    getChannelVerification(recent4WeeksAgo, yesterday)
  );
  const { data: thisMonthData = [] } = useQuery([queryKeys.GET_THIS_MONTH_CHANNEL_VERIFI], () =>
    getChannelVerification(firstDayOfMonth, yesterday)
  );

  const handleActiveTab = (value: string) => {
    setActiveTab(value);
  };

  return (
    <div>
      <Tab tabs={TABS} activeTab={activeTab} onClickTab={handleActiveTab} />
      <CarouselNavigator tabIndex={TABS.indexOf(activeTab)} slideEvent={(e) => handleActiveTab(TABS[e])}>
        <div>
          <AnalysisMain
            title={`${recent4WeeksAgo} ~ ${yesterday}`}
            excelTitle={formatExcelTitle("채널별 본인인증 사용자 수", recent4WeeksAgo, yesterday)}
          >
            <LineGraph dataList={recent4Data} handleDateFormat={formatRecentWeek} interval={6} />
          </AnalysisMain>
        </div>

        <div>
          <AnalysisMain
            title={`${firstDayOfMonth} ~ ${yesterday}`}
            excelTitle={formatExcelTitle("채널별 본인인증 사용자 수", recent4WeeksAgo, yesterday)}
          >
            <LineGraph dataList={thisMonthData} handleDateFormat={formatDayTicks} interval={6} />
          </AnalysisMain>
        </div>
      </CarouselNavigator>
    </div>
  );
}
