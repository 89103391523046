import { TPointGoods, TPointPurchase } from "types/graph";
import { Axios } from "./base";

export const getTotalPointUsed = async (startDate: string, endDate: string): Promise<{ totalPoint: number }> => {
  const { data } = await Axios.get(`/point/total`, { params: { startDate, endDate } });
  return data.data;
};

export const getPointmallTotal = async (startDate: string, endDate: String): Promise<TPointPurchase[]> => {
  const { data } = await Axios.get(`/point/data`, { params: { startDate, endDate } });
  return data.data;
};

export const getlPointRanking = async (startDate: string, endDate: string, count: number): Promise<TPointGoods[]> => {
  const { data } = await Axios.get(`/point/ranking`, { params: { startDate, endDate, count } });
  return data.data;
};
