import { TRewardData } from "types/graph";
import { Axios } from "./base";

export const getRewardData = async (
  category: "data-transaction" | "attendance" | "survey" | "vote",
  startDate: string,
  endDate: string
): Promise<TRewardData[]> => {
  const { data } = await Axios.get(`/rewards/${category}`, { params: { startDate, endDate } });
  return data.data;
};

export const getPaymentAmount = async (startDate: string, endDate: string): Promise<{ amountSum: number }> => {
  const { data } = await Axios.get(`/rewards/payment-amount`, { params: { startDate, endDate } });
  return data.data;
};
