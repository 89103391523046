import { TChallengeRate, TWeekAmountSum } from "types/graph";
import { Axios } from "./base";

export const getChallengePoint = async (
  type: string,
  startDate: string,
  endDate: string
): Promise<TWeekAmountSum[]> => {
  const { data } = await Axios.get(`/rewards/challenge/amount`, { params: { type, startDate, endDate } });
  return data.data;
};

export const getChallengeRate = async (startDate: string, endDate: string): Promise<TChallengeRate[]> => {
  const { data } = await Axios.get(`/rewards/challenge/rate`, { params: { startDate, endDate } });
  return data.data;
};
