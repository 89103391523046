import { AxiosError } from "axios";
import ErrorCode from "constants/errorCode";

export const failureHandling = (e: unknown) => {
  if (e instanceof AxiosError) {
    const statusMap: Record<string | number, string> = {
      400: ErrorCode.INVALID_ARGUMENT,
      401: ErrorCode.UNAUTHORIZED,
      404: ErrorCode.RESOURCE_NOT_FOUND,
      405: ErrorCode.UNSUPPORTED_HTTPVERB,
      500: ErrorCode.INTERNAL_SERVER_ERROR,
      default: ErrorCode.UNKNOWN_ERROR,
    };
    const serverExceptionCodeMap: Record<string | number, string> = {
      6002: e.response?.data.message || ErrorCode.INVALID_ARGUMENT,
      6060: e.response?.data.message || ErrorCode.INVALID_ARGUMENT,
      7804: e.response?.data.message || ErrorCode.INVALID_ARGUMENT,
    };

    throw new Error(
      e.response
        ? serverExceptionCodeMap[e.response?.data.code] || statusMap[e.response.status] || statusMap.default
        : statusMap.default
    );
  }

  if (e instanceof Error) {
    throw new Error(e.message);
  }

  throw new Error(ErrorCode.UNKNOWN_ERROR);
};
