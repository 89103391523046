import styles from "styles/common/tab.module.scss";
import cx from "classnames";

type TProps = {
  tabs: string[];
  activeTab: string;
  onClickTab: (value: string) => void;
};

export default function Tab({ tabs, activeTab, onClickTab }: TProps) {
  return (
    <ul className={styles.tab}>
      {tabs.map((tab) => {
        return (
          <li key={tab} className={cx({ [styles.active]: activeTab === tab })}>
            <button value={tab} onClick={(e) => onClickTab(e.currentTarget.value)} type="button">
              {tab}
            </button>
          </li>
        );
      })}
    </ul>
  );
}
