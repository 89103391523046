import { Bar, BarChart, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

type TProps = {
  dataList: { goodsName: string; brandName: string; tradeCount: number }[];
};

type TTickProps = {
  x: number;
  y: number;
  payload: {
    value: string;
    index: number;
  };
};

export default function VerticalBarGraph({ dataList }: TProps) {
  const handleYAxisTickLabel = ({ x, y, payload }: TTickProps) => {
    const goodsName = payload.value;
    const brandName = dataList[payload.index].brandName;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={0} textAnchor="end" fontSize={10}>
          {goodsName}
        </text>
        <text x={0} y={10} dy={0} textAnchor="end" fill="#999" fontSize={10}>
          {brandName}
        </text>
      </g>
    );
  };

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        layout="vertical"
        data={dataList}
        margin={{
          top: 30,
          right: 30,
          left: 70,
          bottom: 20,
        }}
      >
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            fontSize: "12px",
            paddingTop: "12px",
          }}
          iconType="rect"
          formatter={() => {
            return "상품별 판매 건수";
          }}
        />
        <XAxis
          type="number"
          tick={{ fontSize: 12 }}
          label={{
            fontSize: "12",
            offset: "-5",
            value: "(단위 : 건)",
            position: "insideBottomLeft",
          }}
          tickFormatter={(yTick) => {
            return yTick.toLocaleString();
          }}
        />
        <YAxis dataKey="goodsName" tick={handleYAxisTickLabel} type="category" />
        <Tooltip
          contentStyle={{
            borderRadius: "8px",
            backgroundColor: "#f4fbff",
            border: 0,
            fontSize: 14,
            fontWeight: 500,
            boxShadow: "0 2px 4px #a9aeb1",
          }}
          trigger="click"
          labelStyle={{ fontWeight: 600 }}
          formatter={(value: number) => value.toLocaleString()}
        />
        <Legend />
        <Bar
          dataKey="tradeCount"
          barSize={12}
          fill="#413ea0"
          activeBar={<Rectangle fill="pink" stroke="blue" animationDuration={0} />}
          animationDuration={0}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}
