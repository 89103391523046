import { TDateCount, TDateCountList } from "types/graph";
import { Axios } from "./base";

export const getTotalVerification = async (startDate: string, endDate: string): Promise<TDateCount[]> => {
  const { data } = await Axios.get(`/verification/count-all`, { params: { startDate, endDate } });
  return data.data;
};

export const getChannelVerification = async (startDate: string, endDate: string): Promise<TDateCountList[]> => {
  const { data } = await Axios.get(`/verification/count-by-route`, { params: { startDate, endDate } });
  return data.data;
};
