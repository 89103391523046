import styles from "styles/common/header.module.scss";
import { ToastIcon } from "assets/svgs";
import SideBar from "./SideBar";
import { useState } from "react";

function Header({ text, borderLine }: { text?: string; borderLine?: boolean }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const handleSidebarToggle = () => {
    setIsSidebarOpen((prev) => !prev);
  };

  return (
    <div>
      <div className={styles.wrapper}>
        <header className={styles.header}>
          <button type="button" className={styles.sideBtn} onClick={handleSidebarToggle}>
            <ToastIcon />
          </button>
          <h2>{text}</h2>
          {borderLine && <hr />}
        </header>
      </div>
      <SideBar isOpen={isSidebarOpen} onClose={() => setIsSidebarOpen(false)} />
    </div>
  );
}

export default Header;
