const queryKeys = {
  GET_RECENT_4WEEKS_DAU: ["GET_RECENT_4WEEKS_DAU"],
  GET_THIS_MONTH_DAU: ["GET_THIS_MONTH_DAU"],
  GET_DAY_OF_WEEK_DAU: ["GET_DAY_OF_WEEK_DAU"],
  GET_WEEK_BY_WEEK_DAU: ["GET_WEEK_BY_WEEK_DAU"],
  GET_RECENT_1YEAR_MAU: ["GET_RECENT_1YEAR_MAU"],
  GET_THIS_YEAR_MAU: ["GET_THIS_YEAR_MAU"],
  GET_RECENT4_VERIFI: ["GET_RECENT4_VERIFI"],
  GET_THIS_MONTH_VERIFI: ["GET_THIS_MONTH_VERIFI"],
  GET_RECENT4_CHANNEL_VERIFI: ["GET_RECENT4_CHANNEL_VERIFI"],
  GET_THIS_MONTH_CHANNEL_VERIFI: ["GET_THIS_MONTH_CHANNEL_VERIFI"],
  GET_RECENT4_DATA_TRANSACTION: ["GET_RECENT4_DATA_TRANSACTION"],
  GET_THIS_MONTH_DATA_TRANSACTION: ["GET_THIS_MONTH_DATA_TRANSACTION"],
  GET_RECENT4_ATTENDANCE: ["GET_RECENT4_ATTENDANCE"],
  GET_THIS_MONTH_ATTENDANCE: ["GET_THIS_MONTH_ATTENDANCE"],
  GET_RECENT4_POLL: ["GET_RECENT4_POLL"],
  GET_THIS_MONTH_POLL: ["GET_THIS_MONTH_POLL"],
  GET_RECENT4_SURVEY: ["GET_RECENT4_SURVEY"],
  GET_THIS_MONTH_SURVEY: ["GET_THIS_MONTH_SURVEY"],
  GET_RECENT4_CHALLENGE_TOTAL: ["GET_RECENT4_CHALLENGE_TOTAL"],
  GET_RECENT4_CHALLENGE_NET: ["GET_RECENT4_CHALLENGE_NET"],
  GET_THIS_MONTH_CHALLENGE_TOTAL: ["GET_THIS_MONTH_CHALLENGE_TOTAL"],
  GET_THIS_MONTH_CHALLENGE_NET: ["GET_THIS_MONTH_CHALLENGE_NET"],
  GET_RECENT4_CHALLENGE_RATE: ["GET_RECENT4_CHALLENGE_RATE"],
  GET_THIS_MONTH_CHALLENGE_RATE: ["GET_THIS_MONTH_CHALLENGE_RATE"],
  GET_RECENT4_POINTMALL: ["GET_RECENT4_POINTMALL"],
  GET_THIS_MONTH_POINTMALL: ["GET_THIS_MONTH_POINTMALL"],
  GET_POINT_GOODS_RANKING: ["GET_POINT_GOODS_RANKING"],
  GET_HOME_DATA: ["GET_HOME_DATA"],
} as const;

export default queryKeys;
