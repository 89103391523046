import { XAxis, YAxis, CartesianGrid, Bar, Legend, ComposedChart, Tooltip, Line, ResponsiveContainer } from "recharts";
import { TPointPurchase, TRewardData } from "types/graph";
import { CustomTooltip } from "common/CustomTooltip";
import { useLocation } from "react-router-dom";

type TProps = {
  dataList: TRewardData[] | TPointPurchase[];
  handleDateFormat: (xTick: number) => string;
  formatLegendText: (dataKey: string) => string;
  tabType: string;
};

export default function ComposedGraph({ dataList, handleDateFormat, formatLegendText, tabType }: TProps) {
  const location = useLocation();
  const path = location.pathname;

  const unitOfMeasure = path.includes("point") ? "P" : "원";
  const barDataKey = path.includes("point") ? "totalPoint" : "amountSum";
  const lineDataKey = path.includes("point") ? "purchaseCount" : "tradeCount";

  return (
    <ResponsiveContainer width="100%">
      <ComposedChart
        data={dataList}
        margin={{
          top: 30,
          right: 10,
          left: 10,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            paddingLeft: "20px",
            fontSize: "12px",
          }}
          iconType="rect"
          formatter={formatLegendText}
        />
        <XAxis dataKey="week" tick={{ fontSize: 12 }} tickFormatter={handleDateFormat} />
        <YAxis
          tick={{ fontSize: 11 }}
          label={{
            fontSize: "12",
            offset: "8",
            value: `(단위 : ${unitOfMeasure})`,
            position: "insideTopLeft",
          }}
          yAxisId="left"
          tickFormatter={(yTick) => {
            return yTick.toLocaleString();
          }}
        />
        <YAxis
          tick={{ fontSize: 12 }}
          label={{
            fontSize: "12",
            offset: "8",
            value: "(단위 : 건)",
            position: "insideTopRight",
          }}
          orientation="right"
          yAxisId="right"
          tickFormatter={(yTick) => {
            return yTick.toLocaleString();
          }}
        />
        <Tooltip trigger="click" content={<CustomTooltip />} />
        <Bar dataKey={barDataKey} barSize={20} fill="#413ea0" yAxisId="left" unit="원" id={tabType} />
        <Line
          type="monotone"
          dataKey={lineDataKey}
          stroke="#ff7300"
          strokeWidth={1.5}
          yAxisId="right"
          unit="건"
          id={tabType}
          animationDuration={0}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
}
