import { getWeekByWeekDAU } from "api/activeUser";
import LineGraph from "common/graph/LineGraph";
import queryKeys from "constants/queryKeys";
import AnalysisMain from "pages/AnalysisMain";
import { useQuery } from "react-query";
import { formatExcelTitle, lastSunday, monday8WeeksAgo } from "utils/dateCalculator";

type TProps = {
  enabled: boolean;
};

export default function WeekByWeekDAU({ enabled }: TProps) {
  const { data: weekByWeekDAU = [] } = useQuery(
    [queryKeys.GET_WEEK_BY_WEEK_DAU],
    () => getWeekByWeekDAU(monday8WeeksAgo, lastSunday),
    { enabled }
  );

  return (
    <AnalysisMain
      title={`${monday8WeeksAgo} ~ ${lastSunday}`}
      excelTitle={formatExcelTitle("주차별 DAU", monday8WeeksAgo, lastSunday)}
    >
      <LineGraph dataList={weekByWeekDAU} interval={0} />
    </AnalysisMain>
  );
}
