import styles from "styles/common/sideBar.module.scss";
import { HomeIcon, CloseIcon } from "assets/svgs";
import { CATEGORY_LIST } from "constants/category";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import useOnClickOutside from "hooks/useOnClickOutside";
import useModalScrollPrevent from "hooks/useModalScrollPrevent";
import cx from "classnames";
import { postLogout } from "api/login/login";
import { useMutation } from "react-query";

type TProps = {
  isOpen: boolean;
  onClose: () => void;
};

export default function SideBar({ isOpen, onClose }: TProps) {
  const location = useLocation();
  const path = location.pathname;

  const ref = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();
  const { mutate: logoutMutate } = useMutation(postLogout, {
    onSuccess: () => {
      navigate("/login");
    },
    onError: (error) => {
      console.error("Logout failed:", error);
    },
  });

  const navigateToPath = (path: string) => {
    onClose();
    navigate(path);
  };

  const handleLogout = () => {
    logoutMutate();
  };

  useOnClickOutside(ref, () => onClose());
  useModalScrollPrevent(isOpen);

  return (
    <div className={cx(styles.container, { [styles.open]: isOpen })}>
      <div ref={ref} className={cx(styles.wrapper, { [styles.open]: isOpen })}>
        <div className={styles.btnWrapper}>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
          <button onClick={() => navigateToPath("/home")}>
            <HomeIcon />
          </button>
        </div>

        <div className={styles.innerWrapper}>
          <div className={styles.category}>
            {CATEGORY_LIST.map(({ main, sub }) => (
              <ul key={main} className={styles.mainCategory}>
                <li onClick={() => navigateToPath(sub[0].path)} className={styles.title}>
                  {main}
                </li>
                {sub.map((value) => (
                  <li
                    key={value.title}
                    className={cx(styles.subTitle, { [styles.currentCategory]: path === value.path })}
                    onClick={() => navigateToPath(value.path)}
                  >
                    {value.title}
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>

        <button onClick={handleLogout} className={styles.LogoutBtn}>
          <span>로그아웃</span>
        </button>
      </div>
    </div>
  );
}
