import Tab from "common/Tab";
import { CarouselNavigator } from "common/CarouselNavigator";
import { useEffect, useState } from "react";
import AnalysisMain from "pages/AnalysisMain";
import { useQuery } from "react-query";
import queryKeys from "constants/queryKeys";
import { formatExcelTitle, lastSunday, monday4WeeksAgo } from "utils/dateCalculator";
import VerticalBarGraph from "common/graph/VerticalBarGraph";
import { getlPointRanking } from "api/pointmall";
import styles from "styles/pages/pointmall/ranking.module.scss";

const TABS = ["최근 4주"];

export default function Ranking() {
  const [activeTab, setActiveTab] = useState(TABS[0]);
  const { data: recent4Ranking = [] } = useQuery([queryKeys.GET_POINT_GOODS_RANKING], () =>
    getlPointRanking(monday4WeeksAgo, lastSunday, 10)
  );
  const noData = recent4Ranking.length === 0;

  const handleActiveTab = (value: string) => {
    setActiveTab(value);
  };
  return (
    <div>
      <Tab tabs={TABS} activeTab={activeTab} onClickTab={handleActiveTab} />
      <CarouselNavigator tabIndex={TABS.indexOf(activeTab)} slideEvent={(e) => handleActiveTab(TABS[e])}>
        <div>
          <AnalysisMain
            title={`${monday4WeeksAgo} ~ ${lastSunday}`}
            excelTitle={formatExcelTitle("포인트몰 상품 구매 순위", monday4WeeksAgo, lastSunday)}
          >
            {!noData ? (
              <VerticalBarGraph dataList={recent4Ranking} />
            ) : (
              <p className={styles.noDataText}>아직 충분한 상품 데이터가 없습니다.</p>
            )}
          </AnalysisMain>
        </div>
        <div />
      </CarouselNavigator>
    </div>
  );
}
