import axios, { AxiosError } from "axios";
import { failureHandling } from "./errorHandling";
import { postReissueToken } from "./login/login";

let isTokenBeingReissued = false;

export const Axios = axios.create({
  timeout: 5 * 1000,
  withCredentials: true,
  baseURL: "/moai",
});

Axios.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("accessToken");

    if (accessToken && !config.url?.includes("staff")) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    throw Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (error.response.status === 401 && !isTokenBeingReissued) {
      isTokenBeingReissued = true;
      try {
        await postReissueToken();
      } finally {
        isTokenBeingReissued = false;
      }
    }

    if (error instanceof AxiosError) {
      if (error.message.includes("timeout")) throw new Error("timeout");
      if (!navigator.onLine || error.message === "Network Error") throw new Error("network");
    }

    failureHandling(error);
  }
);
