import { Bar, BarChart, CartesianGrid, Legend, Rectangle, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CustomTooltip } from "common/CustomTooltip";
import { useLocation } from "react-router-dom";

type TProps = {
  dataList: {
    week: number;
    amount: number[];
  }[];
  handleLegendText?: (xTick: string) => JSX.Element;
  handleDateFormat?: (xTick: number) => string;
  pageTitle: string;
  tabType: string;
};

export default function BarGraph({ dataList, handleLegendText, handleDateFormat, pageTitle, tabType }: TProps) {
  const location = useLocation();
  const path = location.pathname;

  const unitOfMeasure = path.includes("point") ? "원" : "%";

  return (
    <ResponsiveContainer width="100%">
      <BarChart
        width={500}
        height={300}
        data={dataList}
        margin={{
          top: 30,
          right: 30,
          left: 10,
          bottom: 20,
        }}
        barCategoryGap="20%"
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            paddingLeft: "40px",
            fontSize: "12px",
          }}
          iconType="rect"
          formatter={handleLegendText}
        />
        <XAxis dataKey="week" tick={{ fontSize: 12 }} tickFormatter={handleDateFormat} />
        <YAxis
          tick={{ fontSize: 12 }}
          label={{
            fontSize: "12",
            offset: "8",
            value: `(단위 : ${unitOfMeasure})`,
            position: "insideTopLeft",
          }}
          tickFormatter={(yTick) => {
            return yTick.toLocaleString();
          }}
        />
        <Tooltip trigger="click" content={<CustomTooltip />} />
        {dataList[0]?.amount.map((data, i) => {
          return (
            <Bar
              key={i}
              dataKey={`amount[${i}]`}
              fill={`hsl(${(i * 170 - 130) % 360}, 90%, 60%)`}
              activeBar={<Rectangle stroke="white" />}
              maxBarSize={20}
              unit={unitOfMeasure}
              id={tabType}
              animationDuration={0}
            />
          );
        })}
      </BarChart>
    </ResponsiveContainer>
  );
}
