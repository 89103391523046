import Header from "common/Header";
import { CATEGORY_LIST } from "constants/category";
import { useEffect } from "react";
import { Outlet, useLocation } from "react-router-dom";
import styles from "styles/pages/mainOutlet.module.scss";

export default function MainOutlet() {
  const location = useLocation();
  const path = location.pathname;

  const customHeader = () => {
    const subCategories = CATEGORY_LIST.flatMap((category) => category.sub);
    const subCategory = subCategories.find((sub) => sub.path === path);

    const title = subCategory?.title;
    const isRewardPath = path.includes("/reward");

    if (path == "/home") return <Header text="home" borderLine />;
    if (isRewardPath) return <Header text={`${title} 건수·지급액`} />;

    return <Header text={title} />;
  };

  useEffect(() => {
    const updateVh = () => {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    };
    updateVh();
    window.addEventListener("resize", updateVh);

    return () => {
      window.removeEventListener("resize", updateVh);
    };
  }, []);

  return (
    <div className={styles.wrapper}>
      <main>
        {customHeader()}
        <Outlet />
      </main>
    </div>
  );
}
