export const CATEGORY_LIST: {
  main: string;
  sub: {
    title: string;
    path: string;
  }[];
}[] = [
  {
    main: "활성 사용자 분석",
    sub: [
      {
        title: "DAU",
        path: "/active/dau",
      },
      {
        title: "MAU",
        path: "/active/mau",
      },
    ],
  },
  {
    main: "본인인증",
    sub: [
      {
        title: "본인인증 사용자 수",
        path: "/auth/total",
      },
      {
        title: "채널별 본인인증 사용자 수",
        path: "/auth/channel",
      },
    ],
  },
  {
    main: "리워드 지급",
    sub: [
      {
        title: "데이터 거래",
        path: "/reward/data-trade",
      },
      {
        title: "출석체크",
        path: "/reward/attendance",
      },
      {
        title: "투표",
        path: "/reward/poll",
      },
      {
        title: "설문조사",
        path: "/reward/survey",
      },
      {
        title: "그룹루틴 챌린지 지급액",
        path: "/challenge/point",
      },
      {
        title: "그룹루틴 챌린지 성공률",
        path: "/challenge/success",
      },
    ],
  },
  {
    main: "포인트몰 사용",
    sub: [
      {
        title: "포인트몰 구매 건수·구매액",
        path: "/pointmall/payment",
      },
      {
        title: "포인트몰 상품 구매 순위",
        path: "/pointmall/ranking",
      },
    ],
  },
];
