import Tab from "common/Tab";
import { CarouselNavigator } from "common/CarouselNavigator";
import AnalysisMain from "pages/AnalysisMain";
import { useState } from "react";
import BarGraph from "common/graph/BarGraph";
import { firstDayOfMonth, formatExcelTitle, lastSunday, monday4WeeksAgo, yesterday } from "utils/dateCalculator";
import queryKeys from "constants/queryKeys";
import { useQuery } from "react-query";
import { getChallengePoint } from "api/challenge";
import { TWeekAmountSum } from "types/graph";

const TABS = ["최근 4주", "이번 달"];

export default function ChallengePoint() {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const { data: recent4TotalData = [] } = useQuery(
    [queryKeys.GET_RECENT4_CHALLENGE_TOTAL],
    () => getChallengePoint("total", monday4WeeksAgo, lastSunday),
    { enabled: activeTab === "최근 4주" }
  );
  const { data: recent4NetData = [] } = useQuery(
    [queryKeys.GET_RECENT4_CHALLENGE_NET],
    () => getChallengePoint("net", monday4WeeksAgo, lastSunday),
    { enabled: activeTab === "최근 4주" }
  );

  const { data: thisMonthTotalData = [] } = useQuery(
    [queryKeys.GET_RECENT4_CHALLENGE_TOTAL],
    () => getChallengePoint("total", firstDayOfMonth, yesterday),
    { enabled: activeTab === "이번 달" }
  );
  const { data: thisMonthNetData = [] } = useQuery(
    [queryKeys.GET_THIS_MONTH_CHALLENGE_NET],
    () => getChallengePoint("net", firstDayOfMonth, yesterday),
    { enabled: activeTab === "이번 달" }
  );

  const handleActiveTab = (value: string) => {
    setActiveTab(value);
  };

  const changeDataFormat = (data1: TWeekAmountSum[], data2: TWeekAmountSum[]) =>
    data1.map(({ week, amountSum }) => ({
      week,
      amount: [amountSum, data2.find((item) => item.week === week)?.amountSum || 0],
    }));

  const formatRecentWeek = (xTick: number) => {
    const weekAgo = 4 - xTick + 1;
    return `${weekAgo}주전`;
  };

  const formatLegendText = (value: string) => {
    const text = value === "amount[0]" ? "챌린지 지급 총액" : "베팅금액 외 지급액";
    return <span style={{ color: "black" }}>{text}</span>;
  };

  return (
    <div>
      <Tab tabs={TABS} activeTab={activeTab} onClickTab={handleActiveTab} />
      <CarouselNavigator tabIndex={TABS.indexOf(activeTab)} slideEvent={(e) => handleActiveTab(TABS[e])}>
        <AnalysisMain
          title={`${monday4WeeksAgo} ~ ${lastSunday}`}
          excelTitle={formatExcelTitle("그룹루틴 챌린지 지급액", monday4WeeksAgo, lastSunday)}
        >
          <BarGraph
            dataList={changeDataFormat(recent4TotalData, recent4NetData)}
            handleLegendText={formatLegendText}
            handleDateFormat={formatRecentWeek}
            pageTitle="그룹루틴 챌린지 지급액"
            tabType={activeTab}
          />
        </AnalysisMain>
        <AnalysisMain
          title={`${firstDayOfMonth} ~ ${yesterday}`}
          excelTitle={formatExcelTitle("그룹루틴 챌린지 지급액", firstDayOfMonth, yesterday)}
        >
          <BarGraph
            dataList={changeDataFormat(thisMonthTotalData, thisMonthNetData)}
            handleLegendText={formatLegendText}
            handleDateFormat={(xTick) => `${xTick}주차`}
            pageTitle="그룹루틴 챌린지 지급액"
            tabType={activeTab}
          />
        </AnalysisMain>
      </CarouselNavigator>
    </div>
  );
}
