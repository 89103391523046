import styles from "styles/pages/main/dataCard.module.scss";
import { TDataCardList } from "types/graph";

type TProps = TDataCardList;

export default function DataCard({ title, value, description }: TProps) {
  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{title}</p>
      <div className={styles.content}>
        <p>{value?.toLocaleString("ko-KR")}</p>
        <span>{description}</span>
      </div>
    </div>
  );
}
