import { useQuery } from "react-query";
import queryKeys from "constants/queryKeys";
import { getRewardData } from "api/reward";
import { firstDayOfMonth, lastSunday, monday4WeeksAgo, yesterday } from "utils/dateCalculator";
import ComposedLayout from "pages/ComposedLayout";

export default function Attendance() {
  const { data: recent4Data = [] } = useQuery([queryKeys.GET_RECENT4_ATTENDANCE], () =>
    getRewardData("attendance", monday4WeeksAgo, lastSunday)
  );
  const { data: thisMonthData = [] } = useQuery([queryKeys.GET_THIS_MONTH_ATTENDANCE], () =>
    getRewardData("attendance", firstDayOfMonth, yesterday)
  );

  return <ComposedLayout pageTitle="출석체크" recent4Data={recent4Data} thisMonthData={thisMonthData} />;
}
