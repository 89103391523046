import styles from "styles/pages/login/signup.module.scss";
import { PrevIcon } from "assets/svgs";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import cx from "classnames";
import { useMutation } from "react-query";
import { postSignup } from "api/login/signup";
import { TSignup } from "types/login";
import { AxiosError } from "axios";

export default function SignUp() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(-1);
  };

  const { mutate: PostSignupMutate } = useMutation(postSignup);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
  } = useForm<TSignup>({ mode: "onChange" });

  const clickToSignup = ({ userId, password }: TSignup) => {
    PostSignupMutate(
      {
        userId,
        password,
      },
      {
        onSuccess: () => {
          alert(`회원가입 완료! 로그인 후 이용해주세요.`);
          navigate("/login");
        },
        onError: (error) => {
          alert((error as AxiosError).message);
        },
      }
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <button type="button" onClick={handleBackClick}>
          <PrevIcon className={styles.prevBtn} />
        </button>
        <span>회원가입</span>
      </div>

      <div className={styles.content}>
        <p style={{ whiteSpace: "pre-wrap" }}>{"모아이에 오신 것을\n환영합니다!"}</p>
      </div>

      <form className={styles.formWrapper} onSubmit={handleSubmit(clickToSignup)}>
        <div>
          <p className={styles.title}>사용하실 아이디를 입력해주세요.</p>
          <div className={styles.idInput}>
            <input
              placeholder="아이디 입력"
              {...register("userId", {
                required: true,
                minLength: 1,
                maxLength: 20,
              })}
            />
            <span>@snplab.io</span>
          </div>
          {errors.userId?.type === "maxLength" && <p className={styles.error}>1~20자 내로 입력해주세요</p>}
        </div>

        <div>
          <p className={styles.title}>사용하실 비밀번호를 입력해주세요.</p>
          <input
            placeholder="비밀번호 입력"
            type="password"
            autoComplete="off"
            {...register("password", {
              required: true,
              pattern: {
                value: /^(?=.*[a-zA-Z])(?=.*[0-9])(?=.*[!@#$%^&*?_]).{8,20}$/,
                message: "영문, 숫자, 특수문자를 포함해 8~20자 내로 입력해주세요",
              },
            })}
            className={styles.pwInput}
          />
          {errors.password && (
            <p className={cx(styles.comment, { [styles.error]: errors.password?.type === "pattern" })}>
              영문, 숫자, 특수문자를 포함해 8~20자 내로 입력해주세요
            </p>
          )}
        </div>

        <button type="submit" disabled={!isValid || isSubmitting}>
          확인
        </button>
      </form>
    </div>
  );
}
