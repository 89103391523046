import dayjs from "dayjs";
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  LineChart,
  Line,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
} from "recharts";
import { NameType, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { TDateCountList } from "types/graph";
import { handleCountOfWeek, monday8WeeksAgo } from "utils/dateCalculator";
import styles from "styles/common/graph/tooltip.module.scss";

type TProps = {
  dataList: TDateCountList[];
  handleDateFormat?: (xTick: string) => string;
  interval: number;
};

export default function LineGraph({ dataList, handleDateFormat, interval }: TProps) {
  const dataKey = dataList[0] ? ["date", "week", "day"].find((key) => key in dataList[0]) : "date";

  const formatLegendText = (value: string) => {
    const label = value.split(".")[1];

    if (dataKey === "day") {
      return 8 - parseInt(label) + 1 + "주전";
    }
    return <span>{label}</span>;
  };

  const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
    const handleValueKey = (key: string) => (dataKey === "day" ? `${9 - +key}주전` : key);
    const handleTitle = (pld: any) => {
      if (dataKey === "week") {
        const weekData = pld.week;
        const { month, week } = handleCountOfWeek(
          dayjs(monday8WeeksAgo)
            .add(weekData - 1, "w")
            .format()
        );
        return `${month}월 ${week}주`;
      }
      return pld.date || pld.day;
    };

    if (active && payload && payload.length) {
      return (
        <div className={styles.tooltip}>
          <p>{handleTitle(payload[0].payload)}</p>
          {payload.map((pld) => (
            <div key={pld.dataKey}>
              <div className={styles.box} style={{ backgroundColor: pld.color }} />
              <span>{handleValueKey(`${typeof pld.dataKey === "string" && pld.dataKey?.split(".")[1]}`)}</span>
              <span>{` : ${pld.value?.toLocaleString()}`}</span>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <ResponsiveContainer width="100%">
      <LineChart
        data={dataList}
        margin={{
          top: 30,
          right: 30,
          left: 10,
          bottom: 20,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <Legend
          verticalAlign="bottom"
          wrapperStyle={{
            paddingLeft: "40px",
            fontSize: "12px",
          }}
          iconType="rect"
          formatter={formatLegendText}
        />
        <XAxis dataKey={dataKey} tick={{ fontSize: 12 }} tickFormatter={handleDateFormat} interval={interval} />
        <YAxis
          tick={{ fontSize: 12 }}
          label={{
            fontSize: "12",
            offset: "8",
            value: "(단위 : 명)",
            position: "insideTopLeft",
          }}
          domain={[
            (dataMin: number) => Math.floor((dataMin * 0.9) / 100) * 100,
            (dataMax: number) => Math.ceil((dataMax * 1.1) / 100) * 100,
          ]}
          tickFormatter={(yTick) => {
            return yTick.toLocaleString();
          }}
        />
        {dataList[0]?.count &&
          Object.keys(dataList[0]?.count).map((data, i) => {
            return (
              <Line
                type="monotone"
                dot={false}
                key={`line_${i}`}
                dataKey={`count.${data}`}
                stroke={`hsl(${(i * 60 + 200) % 360}, 40%, 60%)`}
                strokeWidth={1.5}
              />
            );
          })}
        <Tooltip trigger="click" content={<CustomTooltip />} />
      </LineChart>
    </ResponsiveContainer>
  );
}
