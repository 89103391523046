import { ReactNode, useEffect, useState } from "react";
import styled from "styled-components";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import SwiperCore from "swiper";

type TProps = {
  children: ReactNode[];
  tabIndex: number;
  slideEvent: (activeIndex: number) => void;
};

export function CarouselNavigator({ children, tabIndex, slideEvent }: TProps) {
  const [swiper, setSwiper] = useState<SwiperCore>();

  useEffect(() => {
    swiper?.slideTo(tabIndex);
  }, [tabIndex]);

  return (
    <CustomSwiper
      initialSlide={0}
      slidesPerView={1}
      onSwiper={setSwiper}
      onSlideChange={(swiper) => slideEvent(swiper.activeIndex)}
      touchReleaseOnEdges
    >
      {children.map((child, index) => {
        return <CustomSlide key={index}>{child}</CustomSlide>;
      })}
    </CustomSwiper>
  );
}

const CustomSwiper = styled(Swiper)`
  z-index: 0 !important;
`;

const CustomSlide = styled(SwiperSlide)`
  width: 100%;
`;
