import { getActiveDau } from "api/activeUser";
import queryKeys from "constants/queryKeys";
import AnalysisMain from "pages/AnalysisMain";
import { useQuery } from "react-query";
import { firstDayOfMonth, formatDayTicks, formatExcelTitle, yesterday } from "utils/dateCalculator";
import AreaGraph from "common/graph/AreaGraph";

type TProps = {
  enabled: boolean;
};

export default function ThisMonthDAU({ enabled }: TProps) {
  const { data: thisMonthDAU = [] } = useQuery(
    [queryKeys.GET_THIS_MONTH_DAU],
    () => getActiveDau(firstDayOfMonth, yesterday),
    { enabled }
  );

  return (
    <AnalysisMain
      title={`${firstDayOfMonth} ~ ${yesterday}`}
      excelTitle={formatExcelTitle("DAU", firstDayOfMonth, yesterday)}
    >
      <AreaGraph
        dataList={thisMonthDAU}
        handleDateFormat={formatDayTicks}
        interval={6}
        legendTitle="일일 활성 사용자 수(DAU)"
      />
    </AnalysisMain>
  );
}
