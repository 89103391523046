import Tab from "common/Tab";
import { CarouselNavigator } from "common/CarouselNavigator";
import { useState } from "react";
import AnalysisMain from "pages/AnalysisMain";
import ComposedGraph from "common/graph/ComposedGraph";
import { firstDayOfMonth, formatExcelTitle, lastSunday, monday4WeeksAgo, yesterday } from "utils/dateCalculator";
import { TRewardData } from "types/graph";

const TABS = ["최근 4주", "이번 달"];

type TProps = {
  pageTitle: string;
  recent4Data: TRewardData[];
  thisMonthData: TRewardData[];
};

export default function ComposedLayout({ pageTitle, recent4Data, thisMonthData }: TProps) {
  const [activeTab, setActiveTab] = useState(TABS[0]);

  const handleActiveTab = (value: string) => {
    setActiveTab(value);
  };

  const formatRecentWeek = (xTick: number) => {
    const weekAgo = recent4Data.length - xTick + 1;
    return `${weekAgo}주전`;
  };

  const formatLegendText = (value: string) => {
    return value === "amountSum" ? `${pageTitle} 지급액` : `${pageTitle} 건수`;
  };

  return (
    <div>
      <Tab tabs={TABS} activeTab={activeTab} onClickTab={handleActiveTab} />
      <CarouselNavigator tabIndex={TABS.indexOf(activeTab)} slideEvent={(e) => handleActiveTab(TABS[e])}>
        <div>
          <AnalysisMain
            title={`${monday4WeeksAgo} ~ ${lastSunday}`}
            excelTitle={formatExcelTitle(`${pageTitle} 건수·지급액`, monday4WeeksAgo, lastSunday)}
          >
            <ComposedGraph
              dataList={recent4Data}
              handleDateFormat={formatRecentWeek}
              formatLegendText={formatLegendText}
              tabType={activeTab}
            />
          </AnalysisMain>
        </div>

        <div>
          <AnalysisMain
            title={`${firstDayOfMonth} ~ ${yesterday}`}
            excelTitle={formatExcelTitle(`${pageTitle} 건수·지급액`, firstDayOfMonth, yesterday)}
          >
            <ComposedGraph
              dataList={thisMonthData}
              handleDateFormat={(xTick) => `${xTick}주차`}
              formatLegendText={formatLegendText}
              tabType={activeTab}
            />
          </AnalysisMain>
        </div>
      </CarouselNavigator>
    </div>
  );
}
