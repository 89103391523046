import { Axios } from "../base";
import { TLogin } from "types/login";

export const postLogin = async ({ userId, password }: TLogin) => {
  try {
    const { headers } = await Axios.post(`/staff/login`, { userId, password });

    const accessToken = headers["authorization"];
    if (!accessToken) throw new Error("missing token");

    localStorage.removeItem("accessToken");
    localStorage.setItem("accessToken", accessToken);

    return accessToken;
  } catch (error) {
    throw error;
  }
};

export const postLogout = async () => {
  try {
    await Axios.post(`/staff/logout`);
    localStorage.removeItem("accessToken");

    return { success: true };
  } catch (error) {
    return { success: false, error };
  }
};

export const postReissueToken = async () => {
  try {
    const { headers } = await Axios.post(`/staff/reissue`);
    const newAccessToken = headers["authorization"];

    localStorage.removeItem("accessToken");
    localStorage.setItem("accessToken", newAccessToken);

    return newAccessToken;
  } catch (error) {
    postLogout();
    window.location.href = "/login";
    throw error;
  }
};
