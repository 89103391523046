import dayjs from "dayjs";
import { TooltipProps } from "recharts";
import { NameType, Payload, ValueType } from "recharts/types/component/DefaultTooltipContent";
import { firstDayOfMonth, handleCountOfWeek, monday4WeeksAgo } from "utils/dateCalculator";
import styles from "styles/common/graph/tooltip.module.scss";

export const CustomTooltip = ({ active, payload }: TooltipProps<ValueType, NameType>) => {
  if (active && payload && payload.length) {
    const weekData = payload[0].payload.week;
    const recent4WeekCount = handleCountOfWeek(
      dayjs(monday4WeeksAgo)
        .add(weekData - 1, "w")
        .format()
    );

    const thisMonthWeekCount = handleCountOfWeek(
      dayjs(firstDayOfMonth)
        .add(weekData - 1, "w")
        .format()
    );

    const handleDuplicatedWeeks = () => {
      const nextWeekCount = handleCountOfWeek(dayjs(monday4WeeksAgo).add(weekData, "w").format());
      if (recent4WeekCount.month !== nextWeekCount.month && nextWeekCount.week === 2) {
        return `~ ${nextWeekCount.month}월 1주`;
      }
      return "";
    };

    return (
      <div className={styles.tooltip}>
        <p>
          {(payload[0] as Payload<ValueType, NameType> & { id: string }).id == "최근 4주"
            ? `${recent4WeekCount.month}월 ${recent4WeekCount.week}주 ${handleDuplicatedWeeks()}`
            : `${thisMonthWeekCount.month}월 ${thisMonthWeekCount.week}주차`}
        </p>
        {payload.map((pld) => (
          <div key={pld.dataKey}>
            <div className={styles.box} style={{ backgroundColor: pld.color }} />
            <span>
              {pld.value?.toLocaleString()}
              {pld.unit}
            </span>
          </div>
        ))}
      </div>
    );
  }
};
